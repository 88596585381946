export enum DialogType {
  Premium = 'premium',
  ServicePreferences = 'servicePreferences',
}
export interface PremiumDialogViewModel {
  type: DialogType;
  isOpen: boolean;
}

export interface ServicePreferencesDialogViewModel {
  type: DialogType;
  data: any;
  isOpen: boolean;
}

export type DialogViewModel =
  | PremiumDialogViewModel
  | ServicePreferencesDialogViewModel;

export const createDialogViewModel = ({
  type = DialogType.Premium,
  isOpen = false,
}: {
  type?: string;
  isOpen?: boolean;
}): DialogViewModel => {
  return {
    data: undefined,
    type: type as DialogViewModel['type'],
    isOpen,
  };
};
